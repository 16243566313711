import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Alert, Button, Dropdown, DropdownButton, Form, InputGroup, Spinner} from "react-bootstrap";
import logo from "./images/favicon_logo.svg"
import logoFull from "./images/logo-full.svg"
import narrow from "./images/narrow.svg"
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import {ethers} from "ethers";
// @ts-ignore
import {Store} from 'react-notifications-component'
//import ReactGA from 'react-ga4';

//ReactGA.initialize('G-KXGKN86DVX');
//ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

function App() {
    const [address, setAddress] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [networkID, setNetworkID] = useState()
    const [txHash, setTxHash] = useState({symbol: '', hash: ''})
    const recaptchaRef = useRef(null);
    const notification = {
        title: "",
        message: "",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        dismiss: {
            duration: 4000,
            onScreen: true
        }
    };
    const faucetToken = async (symbol: string) => {
        if (!ethers.utils.isAddress(address)) {
            // @ts-ignore
            Store.addNotification({...notification, title: 'Invalid ethereum address', type: "warning"})
            return
        }
        setTxHash({symbol: '', hash: ''})
        setIsLoading(true)
        // @ts-ignore
        const token = await recaptchaRef.current.executeAsync();
        // @ts-ignore
        recaptchaRef.current.reset()

        await fetch(`/api/v1/faucet`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({address: address, token: token, symbol: symbol})
        }).then(response => response.json())
            .then(data => {
                if (data.code !== 200) {
                    // @ts-ignore
                    Store.addNotification({...notification, title: data.message, type: "danger"})
                } else {
                    setTxHash({hash: data.tx_hash, symbol: symbol})
                }
            })
            .finally(() => {

                setIsLoading(false)
            });

    }
    useEffect(() => {
        // @ts-ignore
        if (!window.ethereum) {
            return
        }
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        provider.getNetwork().then((network:any) => {
            setNetworkID(network.chainId)
        })
        // @ts-ignore
        window.ethereum.on('networkChanged', function(networkId){
            // @ts-ignore
            setNetworkID(parseInt(networkId))
        });
        initAddressInput()
    },[])

    const initAddressInput = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const wallet_address = params.get('wallet_address');
        if (wallet_address) {
            setAddress(wallet_address)
        }
    }

    const inputAddressHandle = (value: any) => {
        setAddress(value.target.value)
    }
    const addToken = (tokenAddress:string, tokenSymbol:string) => {
        try {
            // @ts-ignore
            if (!window.ethereum) {
                // @ts-ignore
                return Store.addNotification({...notification, title: "Need to install Metamask Extension on your browser", type: "warning"})
            }
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: 18, // The number of decimals in the token
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    }
    const addNetwork = () => {
        // @ts-ignore
        if (!window.ethereum) {
            // @ts-ignore
            return Store.addNotification({...notification, title: "Need to install Metamask Extension on your browser", type: "warning"})
        }
        // @ts-ignore
        window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: "0x1B24D",
                    chainName: "Conla Devnet",
                    nativeCurrency: {
                        name: "testnet TON",
                        symbol: "tTON",
                        decimals: 18
                    },
                    rpcUrls: ["https://rpc.devnet.conla.com"],
                    blockExplorerUrls: ["https://explorer.conla.com"]
                }]
        })
    }
    return (
        <div className="App">
            <div className={"app-header"}>
                <div className={"header-logo"}>
                    <img src={logoFull} />
                </div>
                <div className={"header-menu"}>
                    <div className={"header-menu-item"}><a target={"_blank"} href={"https://conla.com/#what-is-conla"}>About</a></div>
                    <div className={"header-menu-item"}><a target={"_blank"} href={"https://docs.conla.com/"}>Build</a></div>
                    <div className={"header-menu-item"}><a target={"_blank"} href={"https://docs.conla.com/users/community"}>Community</a></div>
                    <div className={"header-menu-item add-metamask"}><button type={"button"} onClick={addNetwork}>
                        Add to Metamask</button>
                    </div>
                </div>
            </div>
            <div className={"app-container"}>
                <div className={"faucet-form"}>
                    <img style={{height: 72}} src={logoFull}/>
                    <p>TESTNET FAUCET</p>
                    <div className={"input-container"}>
                    <input
                        className={"address-input"}
                        onChange={inputAddressHandle}
                        value={address}
                        placeholder="Input your address"
                    />
                    <button type={"button"} className={"faucet-btc-btn"} onClick={() => faucetToken('tTON')} disabled={isLoading}>
                        {isLoading ? <Spinner animation="border" variant="dark" size={"sm"}/> : <span><span style={{color: "black"}}>Give me</span> 0.5 tTON</span>}
                    </button>
                    </div>

                    {
                        txHash && txHash.hash && <div className={"tx-alert"}>
                            0.5 {txHash.symbol} has been sent to your wallet. Transaction hash:<br/>
                            <a href={"https://explorer.conla.com/tx/" + txHash.hash}
                                                target={"_blank"}>{txHash.hash}</a>
                            {
                                txHash.symbol === 'tUSDT' &&
                                <p className="mb-0">tUSDT Address: 0x03826BE00C5A22950DA749c37972AF1c01b33Dfd<br/>
                                    <a style={{color: "#fd4800", cursor: "pointer"}} onClick={() => addToken("0x03826BE00C5A22950DA749c37972AF1c01b33Dfd","tUSDT")}>Add tUSDT to Metamask</a>
                                </p>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={"app-footer"}>
                <div className={"app-footer-item"}>
                    <h3>CONSUMER CHAIN</h3>
                    <p>Discover the future of decentralized consumer apps with Conla: seamless, scalable, and built for everyone.</p>
                    <button type={"button"} onClick={() => {window.open('https://docs.conla.com/','_blank')}}>FIND OUT MORE <img src={narrow}/></button>
                </div>
                <div className={"app-footer-item"}>
                    <h3>AA WALLET</h3>
                    <p>Experience top-notch security and seamless accessibility with Conla's cutting-edge wallet with Account Abstraction</p>
                    <button type={"button"} >COMING SOON <img src={narrow}/></button>
                </div>
                <div className={"app-footer-item"}>
                    <h3>COMMUNITY</h3>
                    <p>Stay updated on the latest product developments and be the first to know about upcoming airdrops!</p>
                    <button type={"button"} onClick={() => {window.open('https://docs.conla.com/users/community','_blank')}}>JOIN OUR CREW <img src={narrow}/></button>
                </div>
            </div>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LcuKpcpAAAAAIBMvvUiOdLqCoI4sxhS0Jb73NdG"
            />
        </div>
    );
}

export default App;
